import { Box, Container, Grid, Button } from "@mui/material";
import { Budget } from "../../components/dashboard/budget";
import { Sales } from "../../components/dashboard/sales";
import { TasksProgress } from "../../components/dashboard/tasks-progress";
import { TotalCustomers } from "../../components/dashboard/total-customers";
import { TotalProfit } from "../../components/dashboard/total-profit";
//import { TrafficByDevice } from "../../components/dashboard/traffic-by-device";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme";
import { useEffect, useState } from "react";
import axios from "axios";

function Dashboard() {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(true);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [employee, setEmployee] = useState();
    const [users, setUsers] = useState(null);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/statistic/getalladmin`).then((res) => {
            setData(res.data.data);
            setLoading(false);
        });
        /*axios.get(`${process.env.REACT_APP_API_URL}/auth/getall`, {}).then((res) => {
            setData2(res.data.data);
            setUsers(res.data.data);
        });*/
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {}).then((res) => {
            localStorage.setItem("data1", JSON.stringify(res.data.data));
            setEmployee(res.data.data);
        });
    }, []);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 8
                            }}>
                            {isLoading ? (
                                <div className="loading" style={{ maxWidth: "100%" }}>
                                    <div className="loader"></div>
                                </div>
                            ) : null}
                            <Container maxWidth={false}>
                                <Grid container spacing={3}>
                                    <Grid item xl={3} lg={6} sm={6} xs={12}>
                                        {data && <TotalCustomers user={data.thanhvien} />}
                                    </Grid>
                                    <Grid item xl={3} lg={6} sm={6} xs={12}>
                                        {data && <Budget tongnap={data.tongnaps} />}
                                    </Grid>
                                    <Grid item xl={3} lg={6} sm={6} xs={12}>
                                        {data && <TasksProgress tongrut={data.tongruts} />}
                                    </Grid>
                                    <Grid item xl={3} lg={6} sm={6} xs={12}>
                                        {data && <TotalProfit tongcuoc={data.tongcuoc} sx={{ height: "100%" }} />}
                                    </Grid>
                                    <Grid item lg={12} md={12} xl={12} xs={12}>
                                        <Sales sx={{ border: "1px solid #E6E8F0" }} data={data} />
                                    </Grid>
                                    {/*<Grid item lg={5} md={5} xl={5} xs={12}>
                                        <TrafficByDevice sx={{ border: "1px solid #E6E8F0", height: "100%" }} data={data} />
                                    </Grid>*/}
                                </Grid>
                            </Container>
                        </Box>
                    }
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default Dashboard;
